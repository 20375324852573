<template>
	<div class="relative">
		<ul 
			class="dates overflow-hidden flex flex-wrap -m-15 px-7"
			:class="{ 'cropped' : cropped }"
			:style="datesStyles"
			ref="dates"
		>
			<Tip
				v-for="(tip, index) in tips"
				:key="index"
				:index="index"
				:tip="tip"
			/>
		</ul>
		<button
			@click="showAll"
			class="show-all-button button text-white bg-blue hover:bg-red focus:outline-none"
			:class="{ 'pointer-events-none opacity-0' : !cropped }"
		>Show all items</button>
	</div>
</template>

<script>
import Tip from '~/components/Tip.vue';

import axios from 'axios';

export default {
	components: {
		Tip
	},
	data() {
		return {
			cropped: true,
			setHeight: null,
			tips: []
		};
	},
	computed: {
		tipsPerRow() {
			if (window.innerWidth >= 992) {
				return 4;
			} else if (window.innerWidth >= 768) {
				return 3;
			} else if (window.innerWidth >= 400) {
				return 2;
			} else {
				return 1;
			}
		},
		totalRows() {
			if (this.tips.length) {
				return this.tips.length / this.tipsPerRow;
			} else {
				return null;
			}
		},
		showRows() {
			const decFirst = Date.parse('December 1 2018');
			const janFirst = Date.parse('January 1 2019');
			const now = Date.now();

			if (now < decFirst || now >= janFirst) {
				return 3;
			} else {
				/* Decide how many rows to show based on what day it is, and how many items we need to show */
				const lastActiveTipIndex = new Date().getDate();

				// Rows to show: one more than the last current item is on
				const rows = Math.ceil(lastActiveTipIndex / this.tipsPerRow) + 1;

				// Make sure we never show less than 3 rows
				return rows >= 3 ? rows : 3;
			}
		},
		rowHeight() {
			if (window.innerWidth > 768) {
				return 150;
			} else {
				return 70;
			}
		},
		height() {
			const { cropped, setHeight } = this;

			if (cropped) {
				const height = this.showRows * this.rowHeight;
				return `${height}px`;
			} else if (setHeight) {
				return setHeight + 'px';
			} else {
				return 'auto';
			}
		},
		datesStyles() {
			return {
				height: this.height
			};
		}
	},
	methods: {
		showAll() {
			const innerHeight = this.$refs.dates.scrollHeight;

			this.setHeight = innerHeight;

			setTimeout(() => {
				this.setHeight = null;
			}, 2000);

			this.cropped = false;
		},
		getTips() {
			axios
				.get('https://holiday.dpr.com/tips-2018.json')
				.then(response => {
					this.tips = response.data.data;
				});
		}
	},
	created() {
		this.getTips();
	},
	watch: {
		totalRows(totalRows) {
			if (this.showRows === totalRows) {
				this.cropped = false;
			}
		}
	}
};
</script>

<style scoped>
.dates {
	position: relative;

	transition: 1s height 0.15s;
}

@screen md {
	.dates {
		transition: 0.75s height 0.15s;
	}
}

.dates:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 150px;

	background-image: linear-gradient(
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1)
	);

	opacity: 0;
	visibility: hidden;
	transition: 0.35s opacity, 0s visibility 0.35s;
}

.cropped:after {
	opacity: 1;
	visibility: visible;
}

.show-all-button {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);

	transition: 0.35s opacity, 0.35s background-color;
}
</style>
