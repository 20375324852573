<template>
	<div
		v-show="active"
		class="modal-container fixed pin flex justify-center items-center py-40 px-15"
	>
		<div class="content-container relative flex flex-col relative rounded">
			<div class="header-image bg-cover bg-center rounded-t" :style="`background-image: url('${tip.image}')`"></div>

			<div class="bg-white px-30 pt-20 pb-40 rounded-b">
				<div class="text-2xl text-red font-serif text-center leading-tight">December {{ num }}{{ numExtension }}</div>

				<div class="tip-content pt-10 p-large text-center" v-html="tip.content"></div>

				<button @click="close" class="pt-20 inline-link block mx-auto text-center text-sm md:text-xs focus:outline-none">Back to the calendar</button>

				<!-- Modal close button -->
				<button @click="close" class="close"></button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		active: {
			type: Boolean
		},
		image: {
			type: String
		},
		tip: {
			type: Object,
			required: true
		}
	},
	methods: {
		close() {
			this.$emit('close');
		},
		handleKeydown(event) {
			if (event.keyCode === 27) {
				this.close();
			}
		},
		getSuffix(i) {
			var j = i % 10;
			var k = i % 100;
			if (j == 1 && k != 11) {
				return "st";
			}
			if (j == 2 && k != 12) {
				return "nd";
			}
			if (j == 3 && k != 13) {
				return "rd";
			}
			return "th";
		}
	},
	computed: {
		num() {
			return this.tip.day;
		},
		numExtension() {
			return this.getSuffix(this.num);
		}
	},
	watch: {
		active(active) {
			if (active) {
				// disable scrolling on the body
				document.querySelector('body').classList.add('no-scroll');

				document.addEventListener('keydown', this.handleKeydown);
			} else {
				// re-enable scrolling on the body
				document.querySelector('body').classList.remove('no-scroll');

				document.removeEventListener('keydown', this.handleKeydown);
			}
		}
	}
};
</script>

<style scoped>
.modal-container {
	/* put on top */
	z-index: 1;
	overflow-y: scroll;

	background-color: rgba(20, 48, 76, 0.7);
}

.content-container {
	width: 650px;
	max-width: 90%;
	margin-top: auto;
	margin-bottom: auto;
}

.header-image {
	height: 200px;
}

@screen sm {
	.header-image {
		min-height: 360px;
		max-height: 450px;
	}
}

.close {
	position: absolute;
	top: 0;
	left: 0;

	width: 35px;
	height: 35px;

	@apply outline-none;
}

.close:before,
.close:after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;

	width: 19px;
	height: 2px;
	@apply bg-white;
}

.close:before {
	transform: translate(-50%, -50%) rotate(45deg);
}

.close:after {
	transform: translate(-50%, -50%) rotate(-45deg);
}

.tip-content >>> a {
	@apply text-blue;

	@apply border-b;
	@apply border-solid;
	@apply border-light-gray;

	transition: 0.35s color, 0.35s border-color;
}

.tip-content >>> a:hover {
	@apply text-dark-blue;
	@apply border-blue;
}
</style>