<template>
  <div id="app">
    <Stripes/>

    <header>
      <div class="w-full max-w-content-padded mx-auto flex justify-center md:justify-start">
        <div class="pb-30 p-20 md:p-20">
          <img src="./assets/images/dpr-logo.svg" alt="DPR">
        </div>
      </div>

      <Hero/>
    </header>
    <main>
      <section class="px-15">
        <div class="max-w-copy mx-auto mt-30 md:mt-60 mb-45 md:mb-80">
          <div class="p-large text-center" v-html="content.introduction"></div>
        </div>
        <div class="max-w-content mx-auto">
          <Tips/>
        </div>
      </section>
      <section
        class="relative mt-60 md:mt-100 pt-40 md:pt-75 pb-40 md:pb-70 bg-blue-darker overflow-hidden"
      >
        <div
          class="absolute pin bg-cover bg-center opacity-15"
          :style="`background-image: url('${ require('~/assets/images/snowflakes.png') }');`"
        ></div>

        <div
          class="relative max-w-medium mx-auto md:px-30 flex flex-wrap md:flex-no-wrap justify-center"
        >
          <img
            :src="content.bookImage"
            alt="The Health Workplace Nudge Book Cover"
            class="relative h-256px mb-35 lg:-mb-75 md:rotate-reverse-6deg"
            style="top: 10px;"
          >
          <div
            class="px-15 md:ml-50 w-full md:w-auto flex-shrink flex flex-wrap justify-center items-center md:justify-start text-center md:text-left"
          >
            <div
              class="w-full flex-no-shrink md:w-auto mb-30 p-large text-white max-w-full"
              v-html="content.bookInformation"
            ></div>
            <a
              class="button button-light bg-white hover:bg-light-gray text-dark-blue inline-block flex-no-shrink"
              :href="content.bookLink"
              v-html="content.bookCTA"
            ></a>
          </div>
        </div>
      </section>
    </main>
    <footer>
      <section class="flex my-50 md:mt-80 md:mb-85 justify-center text-center">
        <div class="px-15">
          <h2 class="text-red text-2xl md:text-4xl font-black">{{ content.socialHeading }}</h2>

          <div class="flex flex-wrap justify-center mt-15 md:mt-30">
            <div class="p-5">
              <a
                target="_blank"
                rel="noopener"
                :href="shareLinks.facebook"
                class="w-full button button-facebook hover:bg-red text-white inline-block flex items-center justify-center"
              >
                <img src="./assets/images/social-icon-facebook.svg" alt="Facebook">
                <span class="hidden md:block ml-7">Facebook</span>
              </a>
            </div>
            <div class="p-5">
              <a
                target="_blank"
                rel="noopener"
                :href="shareLinks.twitter"
                class="w-full button button-twitter hover:bg-red text-white inline-block flex items-center justify-center"
              >
                <img src="./assets/images/social-icon-twitter.svg" alt="Twitter">
                <span class="hidden md:block ml-7">Twitter</span>
              </a>
            </div>
            <div class="p-5">
              <a
                target="_blank"
                rel="noopener"
                :href="shareLinks.linkedin"
                class="w-full button button-linkedin hover:bg-red text-white inline-block flex items-center justify-center"
              >
                <img src="./assets/images/social-icon-linkedin.svg" alt="LinkedIn">
                <span class="hidden md:block ml-7">LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <!-- Dotted line separators -->
        <div class="dotted-line"></div>
        <div class="dotted-line"></div>
        <div class="dotted-line"></div>

        <div class="px-15 py-35 text-sm text-center inline-link-container" v-html="content.footer"></div>
      </section>
    </footer>
  </div>
</template>

<script>
import Stripes from '~/components/Stripes.vue';
import Hero from '~/components/Hero.vue';
import Tips from '~/components/Tips.vue';

import axios from 'axios';

export default {
	name: 'app',
	components: {
		Stripes,
		Hero,
		Tips
	},
	data() {
		return {
			content: {}
		};
	},
	computed: {
		shareLinks() {
			const localURL = encodeURI(window.location.href);

			const facebook = `https://www.facebook.com/sharer/sharer.php?u=${localURL}`;
			const twitter = `https://twitter.com/home?status=${localURL}`;
			const linkedin = `https://www.linkedin.com/shareArticle?mini=true&url=${localURL}&title=A%20Health%20Nudge%20Into%20the%20New%20Year&summary=The%20holidays%20can%20be%20magical,%20but%20they%20can%20also%20be%20a%20time%20of%20unhealthy%20living.%20Here%20are%20some%20tips%20to%20nudge%20you%20and%20yours%20to%20a%20year%20of%20wellness.&source=`;

			return {
				facebook,
				twitter,
				linkedin
			};
		}
	},
	methods: {
		getContent() {
			axios
				.get('https://holiday.dpr.com/2018-content.json')
				.then(response => {
					this.content = response.data.data[0];
				})
				.catch(error => {
					this.content = null;
				});
		}
	},
	created() {
		this.getContent();
	}
	// mounted() {
	// 	window.WebFontConfig = { fontdeck: { id: '8204' } };

	// 	(function() {
	// 		var wf = document.createElement('script');
	// 		wf.src =
	// 			('https:' == document.location.protocol ? 'https' : 'http') +
	// 			'://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
	// 		wf.type = 'text/javascript';
	// 		wf.async = 'true';
	// 		var s = document.getElementsByTagName('script')[0];
	// 		s.parentNode.insertBefore(wf, s);
	// 	})();
	// }
};
</script>

<style src="./assets/css/main.css"></style>

<style scoped>
.dotted-line {
	padding: 1.5px 0;
	background-image: linear-gradient(
		to right,
		#d1d2d4 34%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: bottom;
	background-size: 3px 1px;
	background-repeat: repeat-x;
}
</style>

<style>
@charset "utf-8";

@font-face {
	font-family: 'FranklinGothicURW';
	src: url('/dist/fonts/276CAE_2_0.eot');
	src: url('/dist/fonts/276CAE_2_0.eot?#iefix') format('embedded-opentype'),
		url('/dist/fonts/276CAE_2_0.woff') format('woff'),
		url('/dist/fonts/276CAE_2_0.ttf') format('truetype');
}
@font-face {
	font-family: 'FranklinGothicURW';
	src: url('/dist/fonts/276CAE_3_0.eot');
	src: url('/dist/fonts/276CAE_3_0.eot?#iefix') format('embedded-opentype'),
		url('/dist/fonts/276CAE_3_0.woff') format('woff'),
		url('/dist/fonts/276CAE_3_0.ttf') format('truetype');

	font-weight: 900;
}

@font-face {
	font-family: 'Bodini Bold';

	src: url('/dist/fonts/bodonib-webfont.woff2') format('woff2'),
		url('/dist/fonts/bodonib-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
</style>


