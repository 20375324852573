<template>
  <li class="list-reset w-full xs:w-1/2 md:w-1/3 lg:w-1/4 p-7 md:p-15 flex-no-shrink">
    <button
      class="group relative block w-full py-20 md:py-45 px-30 rounded overflow-hidden text-xl font-serif text-center focus:outline-none"
      :class="[
				active
					? 'bg-dark-blue text-white'
					: 'text-dark-blue cursor-default focus:outline-none pointer-events-none'
			]"
      @click="openModal"
    >
      <div
        class="absolute pin bg-cover bg-center ts-opacity"
        :style="bgStyles"
        :class="{ 'opacity-30 group-hover:opacity-40': active }"
      ></div>
      <div class="relative text-base md:text-xl">December {{ num }}</div>
    </button>

    <TipModal :image="bgImage" :active="modalOpen" :tip="tip" @close="closeModal"/>
  </li>
</template>

<script>
import TipModal from '~/components/TipModal.vue';

export default {
	props: {
		index: {
			type: Number,
			required: true
		},
		tip: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			modalOpen: false
		};
	},
	methods: {
		openModal() {
			if (this.active) {
				this.modalOpen = true;
			}
		},
		closeModal() {
			this.modalOpen = false;
		}
	},
	computed: {
		num() {
			return this.tip.day;
		},
		active() {
			return true;
		},
		bgImage() {
			if (this.active) {
				return this.tip.thumbnail;
			} else {
				const imageType = (this.index % 3) + 1;
				return require(`~/assets/images/calendar-tile-bg-${imageType}.png`);
			}
		},
		bgStyles() {
			return {
				backgroundImage: `url('${this.bgImage}')`
			};
		}
	},
	components: {
		TipModal
	}
};
</script>
